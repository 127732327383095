import React from "react";
import {NumberInput, TextInput} from "react-admin";
import useInputStyles from "./InputStyles";

export const NoShadowNumberInput = props => {
    const classes = useInputStyles()

    return (
        <NumberInput
            variant="outlined"
            source={props.source}
            label={props.label}
            disabled={props.disabled}
            helperText={false}
            InputProps={{ classes: { input: classes.input }}} />
    )
}

export const NoShadowInput = props => {
    const classes = useInputStyles()

    return (
        <TextInput
            variant="outlined"
            source={props.source}
            label={props.label}
            disabled={props.disabled}
            helperText={false}
            InputProps={{ classes: { input: classes.input }}}/>
    )
}
