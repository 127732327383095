import React, {useEffect, useState} from "react";
import {useForm} from "react-final-form";
import {SelectInput} from "react-admin";

const dataSource = 'data.protection'

const defaultProtectionType = 'PG'

const emptySelectionItem = { id: '-', name: '-' };

const factoringProtectionTypes = [
    { id: 'PG', name: 'Payment guarantee' },
    { id: 'BG', name: 'Buyback guarantee' },
]

const loanProtectionTypes = [
    { id: 'AG', name: 'Asset guarantee' },
    { id: 'IG', name: 'Insurance guarantee' },
]

const allProtectionTypes = [
    emptySelectionItem,
    ...factoringProtectionTypes,
    ...loanProtectionTypes,
]

export const ProtectionTypeInput = ({ investmentType, ...props }) => {
    const form = useForm()

    const [protectionTypes, setProtectionTypes] = useState([])
    const [currentValue, setCurrentValue] = useState('')

    useEffect(() => {

        const type = investmentType || props.record.data?.type
        let protection = currentValue || props.record.data?.protection

        if (!type) return

        const allowedTypes = getAllowedProtectionTypes(type)
        setProtectionTypes(allowedTypes)

        if (!protection || !allowedTypes.map(i => i.id).includes(protection)) {
            protection = getDefaultType(type)
        }

        if (currentValue !== protection) {
            setCurrentValue(protection)
            form.change(dataSource, protection)
        }
    }, [investmentType, props.record, currentValue, form])

    const getAllowedProtectionTypes = investmentType => {
        const isFactoring = ['F', 'RF', 'IF'].includes(investmentType)
        const isLoan = ['ML', 'L'].includes(investmentType)

        if (isFactoring) {
            return [emptySelectionItem, ...factoringProtectionTypes]
        } else if (isLoan) {
            return [emptySelectionItem, ...loanProtectionTypes]
        } else {
            return allProtectionTypes
        }
    }

    const getDefaultType = investmentType => {
        const isLoan = ['ML', 'L'].includes(investmentType)
        return investmentType && isLoan ? 'AG' : defaultProtectionType
    }

    const handleChange = event => {
        const type = event.target.value
        setCurrentValue(type)
        if (investmentType) form.change(dataSource, type)
    }

    return (
        <SelectInput label="Protection" source={dataSource} helperText={false} optionValue="id" value={currentValue} choices={protectionTypes} {...props} onChange={handleChange} />
    )
}
